import React, { lazy, Suspense } from "react";

const MainPage = lazy(() => import("./MainPage"));
const MapPage = lazy(() => import("./MapPage"));

const Loader = <div />;

function setVh() {
  const vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

const App = () => {
  const [isMapEnabled, enableMap] = React.useState(false);

  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  React.useEffect(() => {
    setVh();
    window.addEventListener("resize", setVh);
  }, []);

  return (
    <Suspense fallback={Loader}>
      {isMapEnabled ? (
        <MapPage toggleMap={() => enableMap(false)} />
      ) : (
        <MainPage toggleMap={() => enableMap(true)} />
      )}
    </Suspense>
  );
};

export default App;
